﻿.umbraco-forms-field {
	font-weight: 600;
	font-family: $base-font-title;
	font-size: 1.125rem;
	// Hide honeypot field
	&.email2 {
		display: none;
	}

	label {
		margin-bottom: 0;
	}
}


::placeholder {
	font-size: 0;

}

input,
select,
textarea {
	height: $form-input-height-large;
	color: $base-text-color;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}

select {
	option {
		background: $color-secondary-light;
	}
}

textarea {
	resize: vertical;
	overflow: auto;
	height: auto;
	min-height: 10rem;

	&::placeholder {
		font-size: 75%;
		font-weight: normal;
		font-family: $base-font-title;
	}
}