﻿.experience {
	box-sizing: border-box;
	background: #fff;
	max-width: 100%;
	padding: 2rem 3rem;
	border: 3px solid $color-primary;
	border-radius: 0.25rem 0.25rem 0.25rem 0;
	position: relative;
	margin: 3.75rem 0 3.75rem 0.625rem;

	.slider-experiences & {
		margin-top: 0.75rem;
		margin-bottom: 2.25rem;
		margin-left: 0;
		left: 0.625rem;
	}

	&:before,
	&:after {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
	}

	&:before {
		border-width: 30px 40px 0 0;
		border-color: $color-primary transparent transparent transparent;
		bottom: -30px;
		left: -3px;
	}

	&:after {
		border-width: 24px 32px 0 0;
		border-color: #fff transparent transparent transparent;
		bottom: -24px;
		left: 0;
	}

	&:nth-of-type(4n+1) {
		background: $color-primary;

		&:after {
			display: none;
		}

		.arrow:after {
			color: $color-secondary;
		}
	}

	&.-yellow,
	&:nth-of-type(4n+3) {
		background: $color-secondary;
		border-color: $color-secondary;

		&:before {
			border-top-color: $color-secondary;
		}

		&:after {
			display: none;
		}

		> div:before {
			border-color: $color-primary;
		}
	}

	> div {
		width: 100%;
		height: 100%;
		display: block;

		&:before {
			content: '';
			border: 3px dashed $color-secondary;
			position: absolute;
			top: -0.812rem;
			right: 0.625rem;
			left: -0.812rem;
			height: 100%;
			border-radius: 0.25rem 0.25rem 0.25rem 0;
			border-radius: 0.25rem;
		}

		h2,
		p,
		a {
			position: relative;
			z-index: 10;
		}
	}
}
