﻿nav.main {
	width: 100%;

	@media (min-width: $grid-breakpoint-m-min) {
		width: auto;
		float: right;
	}

	& > button:first-child {
		position: fixed;
		bottom: 2rem;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1001;
		visibility: visible;
		border: 1px solid rgba($color-secondary-light, 0.5);

		@media (min-width: $grid-breakpoint-m-min) {
			display: none;
			visibility: hidden;
		}

		&:after {
			content: $icon-menu;
			display: inline-block;
			font-size: 2.25rem;
			padding: 0;
			margin: 0;
		}

		&:hover:after {
			right: 0;
		}
	}

	.navigation {
		background: $color-secondary-light url(../../images/fsp-element-green.svg) 100% 100% no-repeat;
		background-size: 50% auto;
		position: fixed;
		top: 0;
		right: -100vw;
		width: 100%;
		height: 100vh;
		padding-top: $grid-column-gutter;
		z-index: 1000;
		transition: right 250ms ease-in-out 0s;

		@media (min-width: $grid-breakpoint-m-min) {
			background: none;
			display: block;
			position: absolute;
			top: 2.25rem;
			right: -1.25rem;
			width: auto;
			height: auto;
			padding-top: 0;
		}
	}

	&.open {

		& > button:first-child:after {
			content: $icon-close;
		}

		.navigation {
			right: 0;
			transition: right 250ms ease-in-out 0s;
		}
	}


	ul {
		@extend .no-bullets;
		width: 100%;
		border-bottom: 1px solid $color-secondary;
		overflow: hidden;
		margin: 0;

		@media (min-width: $grid-breakpoint-m-min) {
			float: right;
			width: auto;
			border-bottom: none;
			overflow: visible;
		}

		li {
			float: left;
			position: relative;
			width: 100%;
			border-top: 1px solid $color-secondary;

			@media (min-width: $grid-breakpoint-m-min) {
				width: auto;
				border-top: none;

				&.open,
				&:hover,
				&:focus,
				&:active {
					background: $color-secondary-light;
					border-radius: 0.25rem;

					a {
						color: $base-text-color;
					}
				}
			}

			a {
				display: block;
				padding: 0.625rem 1.25rem;
				font-size: 1.25rem;
				color: $base-text-color;
				text-decoration: none;

				@media (min-width: $grid-breakpoint-m-min) {
					display: inline-block;
				}

				&:hover,
				&:focus,
				&:active {
					background: $color-secondary-light;
					color: $base-text-color;

					@media (min-width: $grid-breakpoint-m-min) {
						border-radius: 0.25rem;
					}
				}

				&:active {
					font-weight: normal;
					background-color: darken($color-secondary-light, 10); // Darken instead of lighten
				}
			}

			&.sub {
				> a {
					&:before,
					&:after {
						display: inline-block;
						font-size: 50%;
						position: relative;
						right: 0;
						vertical-align: middle;
						transition: opacity 250ms ease-in-out 0s;
					}

					&:before {
						@include icon($icon-chevron-right);
						padding-left: 0.625rem;
						opacity: 0;
						position: absolute;
						right: 1.25rem;
						top: 50%;
						transform: translateY(-50%);
						transition-delay: 125ms;
					}

					&:after {
						@include icon($icon-chevron-down);
						padding-left: 0.625rem;
						transition-delay: 250ms;
					}
				}

				&.open {
					> a:before {
						opacity: 1;
					}

					> a:after {
						opacity: 0;
					}
				}
			}
			// Second level
			ul {
				background-color: darken($color-secondary-light, 10); // Darken instead of lighten
				border-bottom: none;

				@media (min-width: $grid-breakpoint-m-min) {
					display: none;
					background-color: $color-secondary-light;
					position: absolute;
					top: 3.5rem;
					border-radius: 0 0 0.25rem 0.25rem;
				}

				li {
					width: 100%;
					border-top: 1px solid $color-secondary;

					a {
						@extend .-block;
						@extend .arrow;
						position: relative;
						width: 100%;
						padding-right: 4rem;
						padding-left: 1.25rem;
						color: $base-text-color;
						white-space: nowrap;

						&:after {
							right: 1.25rem;
						}
					}
				}
			}

			@media (min-width: $grid-breakpoint-m-min) {
				&.open {
					> ul {
						display: block;
					}
				}
			}
		}
	}
}
