﻿.slogan {
	font-size: $h1-font-size;
	line-height: 3rem;
	font-family: $base-font-title;
	color: $color-secondary-light;
	display: inline-block;
	text-align: left;
	font-style: italic;

	@media (min-width: $grid-breakpoint-m-min) {
		max-width: 12rem;
	}

	strong {
		color: $color-secondary;
	}

	&.-footer {
		color: $color-primary;
	}
}
