﻿.video-link {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
		transition: all 250ms ease-in-out 0s;
	}

	&:before {
		width: 6.25rem;
		height: 4.375rem;
		background: white;
		border-radius: 25% / 15%;
	}

	&:after {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0.875rem 0 0.875rem 1.75rem; // 28px
		border-color: transparent transparent transparent $color-primary;
		margin-left: 0.125rem;
	}

	&:hover,
	&:focus {
		&:before {
			background: $color-secondary;
		}
	}
}
