﻿.block {
	@extend .clearfix;
	background: $color-primary-light;
	position: relative;
	padding: 2rem;
	border-radius: 0.25rem;
	margin: 0 0 $grid-column-gutter;

	@media (min-width: $grid-breakpoint-m-min) {
		padding-right: $grid-column-gutter;
		padding-left: $grid-column-gutter;
	}

	p {
		overflow: hidden;
	}
}
