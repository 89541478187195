﻿.arrow {
	display: inline-block;
	min-height: $form-input-height-small;
	text-align: center;
	font-weight: 600;
	font-family: $base-font-title;
	font-size: 1.124rem;
	color: $base-text-color;
	text-decoration: none;
	vertical-align: middle;
	border: none;
	border-radius: 0.25rem;
	padding: 0.563rem 0; // Vertically align text
	&:after {
		@include icon($icon-arrow-right);
		display: inline-block;
		color: $color-primary;
		position: relative;
		right: 0;
		vertical-align: middle;
		padding-left: 1.875rem;
		transition: right 0.2s ease-out;
	}

	&:hover,
	&:focus,
	&:active {
		color: $base-text-color;

		&:after {
			right: -0.25rem;
		}
	}

	&:focus,
	&:active {
		text-decoration: underline;
	}

	&:active {
		font-weight: 600;
		text-decoration: underline;
	}

	&.-yellow:after {
		color: $color-secondary;
	}

	&.-block {
		text-align: left;
		display: block;
		position: relative;

		&:after {
			position: absolute;
			right: 0.625rem;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover,
		&:focus,
		&:active {
			&:after {
				right: 0.4rem;
			}
		}
	}
}
