﻿.bar {
	background: #000;
	width: 100%;
	min-height: 3.75rem;
	color: #fff;
	font-family: $base-font-title;

	@media (min-width: $grid-breakpoint-m-min) {
		text-align: right;
	}

	p {
		margin: 0;
		padding: 0.75rem 0;
		line-height: 2.25rem;
	}

	a {
		display: block;
		background: url(../../images/sociaal-domein-seal.svg) no-repeat 0 50%;
		background-size: 2.25rem auto;
		font-weight: bold;
		padding-left: 3.25rem;
		text-decoration: none;

		@media (min-width: $grid-breakpoint-xs-min) {
			display: inline-block;
			margin-left: 0.75rem;
		}

		&:after {
			@include icon($icon-chevron-right);
			font-size: 50%;
			margin-left: 0.5rem;
		}
	}
}
