﻿$icon-arrow-down: "\ea01";
$icon-arrow-left: "\ea02";
$icon-arrow-right: "\ea03";
$icon-arrow-up: "\ea04";
$icon-calendar: "\ea05";
$icon-check: "\ea06";
$icon-chevron-down: "\ea07";
$icon-chevron-left: "\ea08";
$icon-chevron-right: "\ea09";
$icon-chevron-up: "\ea0a";
$icon-close: "\ea0b";
$icon-financial: "\ea0c";
$icon-menu: "\ea0d";
$icon-person: "\ea0e";
$icon-phone: "\ea0f";
$icon-question: "\ea10";

@at-root {
	@font-face {
		font-family: icon;
		
		src: url("../../fonts/icon.woff2") format("woff2"), url("../../fonts/icon.woff") format("woff"); 
		font-style: normal;
		font-weight: normal;
	}
}

@mixin icon($content: none) {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: icon;
	line-height: 1;
	text-transform: none;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: middle;
}

.icon:before {
	@include icon;
}

.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-arrow-up:before { content: $icon-arrow-up; }
.icon-calendar:before { content: $icon-calendar; }
.icon-check:before { content: $icon-check; }
.icon-chevron-down:before { content: $icon-chevron-down; }
.icon-chevron-left:before { content: $icon-chevron-left; }
.icon-chevron-right:before { content: $icon-chevron-right; }
.icon-chevron-up:before { content: $icon-chevron-up; }
.icon-close:before { content: $icon-close; }
.icon-financial:before { content: $icon-financial; }
.icon-menu:before { content: $icon-menu; }
.icon-person:before { content: $icon-person; }
.icon-phone:before { content: $icon-phone; }
.icon-question:before { content: $icon-question; }
