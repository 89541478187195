﻿.legal {
	position: relative;
	padding: 0;
	margin: 0 auto;
	overflow: hidden;
	font-weight: 600;
	font-size: 75%;

	@media (min-width: $grid-breakpoint-m-min) {
		margin-top: 3rem;
	}

	ul {
		@extend .no-bullets;
		overflow: hidden;
		margin: 0;

		li {
			float: left;
			margin-right: 0.75rem;
			text-transform: none;
			width: 100%;

			@media (min-width: $grid-breakpoint-s-min) {
				width: auto;
			}
		}
	}
}
