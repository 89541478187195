﻿// Colors
$color-primary: #009f98;
$color-primary-light: #ebf7f7;
$color-secondary: #fcbc00;
$color-secondary-light: #fff4cb;

// General Styles
$font-path: "../fonts/";
$base-font-size: 100%; // 16px
$base-font-size-px: 16px;
$base-line-height: 1.875; // 30px
$base-font-family: Arial, Helvetica, sans-serif;
$base-font-title: 'Nunito', $base-font-family;
$base-text-color: #000;

// Typography
$h1-font-size: 2.5rem; // 40px
$h1-line-height: 1.2; // 48px
$h1-margin: 1.5rem; // 24px

$h2-font-size: 1.875rem; // 30px
$h2-line-height: 1.2; // 36px
$h2-margin: 0.5rem; // 8px

$h3-font-size: 1.375rem; // 22px
$h3-line-height: 1; // 22px
$h3-margin: 1.375rem; // 22px

$h4-font-size: 1rem; // 16px
$h4-line-height: 1.5; // 24px
$h4-margin: 0;

$h5-font-size: 0.875rem; // 14px
$h5-line-height: 1; // 14px
$h5-margin: 0;

$h6-font-size: 0.75rem; // 12px
$h6-line-height: 1.25; // 15px
$h6-margin: 0;

// Selected text
/*$selection-color: #fff;
$selection-background: $base-text-color;*/

// Focused objects (outline color)
$selection-focus-color: #fff;

//==
// Grid System
//##
$grid-container-max-width: 86.125rem; // 1378px
$grid-column-gutter: 3.125rem; // 50px
//$grid-column-count: 12 !default;

$grid-breakpoint-xxs-max: 37.49em;
$grid-breakpoint-xs-min: 37.5em; // 600px
$grid-breakpoint-xs-max: 47.99em;
$grid-breakpoint-s-min: 48em; // 768px
$grid-breakpoint-s-max: 61.99em;
$grid-breakpoint-m-min: 62em; // 992px
$grid-breakpoint-m-max: 74.99em;
$grid-breakpoint-l-min: 75em; // 1200px
$grid-breakpoint-l-max: 119.99em;
$grid-breakpoint-xl-min: 120em; // 1920px
$grid-breakpoint-xl-max: 159.99em;
$grid-breakpoint-xxl-min: 160em; // 2560px

//$grid-print-breakpoint-xs-max: 536px !default;
//$grid-print-breakpoint-s-min: 537px !default;

//==
// Basic Elements
//##

// Horizontal Rule
$horizontal-rule-color: $color-secondary;

// Tables
//$table-cell-pad: .5rem !default;
$table-bordered-border: 0.05rem solid $color-secondary;
$table-striped-background-color: #fff;

//==
// CSS Components
//##

// Forms
//$form-line-height: 1.4 !default;
$form-placeholder: $base-text-color;
$form-input-padding: 0.5rem; // 8px
$form-input-height: 2.5rem; // 40px
$form-input-height-small: 2rem; // 32px
$form-input-height-large: 3.125rem; // 50px
//$form-input-background: #fff !default;
$form-input-border: 0.05rem solid $color-secondary;
//$form-input-border-radius: 0 !default;
$form-input-color: $color-primary;

$form-input-background-disabled: $color-secondary-light;
$form-input-border-color-disabled: $color-primary-light;
$form-input-color-disabled: $color-primary-light;

//$form-input-background-readonly: null !default;
//$form-input-border-color-readonly: #ebebeb !default;
//$form-input-color-readonly: null !default;

// Buttons
$button-background: $color-secondary;
/*$button-border: 0.05rem solid $button-background;*/
$button-color-disabled: $color-secondary-light;

// Lists
//$list-colored-bullet: #00a9ec !default;

// Alerts
//$alert-background-color: #ffd54f !default;
//$alert-border-color: #ff6f00 !default;

// Code
//$code-font-family: monospace, monospace !default;
//$code-background-color: #f7f7f9 !default;
//$code-border-color: #c0c0c0 !default;
//$code-color: #950b2f !default;

//==
// JS Plugins
//##

// Navigation
$navigation-width: 254px;
$navigation-background-color: $color-primary;
//$navigation-color: #fff !default;

// Modal
//$modal-overlay-background-color: rgba(0, 0, 0,.7) !default;

//$modal-header-background: transparent !default;
//$modal-header-color: #fff !default;

//$modal-footer-background: transparent !default;
//$modal-footer-color: #fff !default;

//$modal-content-background-color: #fff !default;

//$modal-media-background-color: #222 !default;

//$modal-control-color: #fff !default;

// Carousel
//$carousel-caption-background-color: rgba(34, 34, 34,.4) !default;
//$carousel-caption-color: #fff !default;

//$carousel-control-color: #fff !default;

//$carousel-nav-background-color: transparent !default;
//$carousel-nav-border: 1px solid #222 !default;

//$carousel-nav-background-color-hover: #c0c0c0 !default;
//$carousel-nav-border-color-hover: null !default;

//$carousel-nav-background-color-active: #fff !default;
//$carousel-nav-border-color-active: null !default;

// Tabs
//$tabs-nav-border-color: #c0c0c0 !default;

//$tabs-nav-item-background-color: null !default;
//$tabs-nav-item-border-color: transparent !default;
//$tabs-nav-item-color: null !default;

//$tabs-nav-item-background-color-hover: #ebebeb !default;
//$tabs-nav-item-border-color-hover: #c0c0c0 !default;
//$tabs-nav-item-color-hover: null !default;

//$tabs-nav-item-background-color-active: #fff !default;
//$tabs-nav-item-border-color-active: #c0c0c0 !default;
//$tabs-nav-item-color-active: #454545 !default;

// Dropdown
//$accordion-border-color: #c0c0c0 !default;