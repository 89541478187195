﻿.banner-link {
	display: block;
	padding: 7px 0;
	font-size: 1.25rem;
	background: $color-secondary;
	text-align: center;
	text-decoration: none;
	transition: background-color 0.2s ease-out;

	span:after {
		@include icon($icon-arrow-right);
		position: relative;
		left: 0.5rem;
		color: $color-primary;
		transition: left 0.2s ease-out;
	}

	&:hover {
		color: #000;
		background-color: darken($color-secondary, 10);
		span:after {
			left: 1rem;
		}
	}

	+ .button {
		background: $color-primary !important;
		color: #fff !important;

		&:after {
			color: $color-secondary !important;
		}

		&:hover {
			background-color: darken($color-primary, 10) !important;
		}
	}
}
