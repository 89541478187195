﻿button,
input[type="submit"],
input[type="button"],
input[type="reset"] {
	min-height: $form-input-height-small;
	text-align: center;
	font-size: 1.124rem;
	font-weight: 600;
	font-family: $base-font-title;
	color: $base-text-color;
	vertical-align: middle;
	transition: background-color 0.2s ease-out;
	border: none;
	border-radius: 0.25rem;
	padding: 0.563rem 1.875rem; // Vertically align text
	&:after {
		@include icon($icon-arrow-right);
		display: inline-block;
		color: $color-primary;
		position: relative;
		right: 0;
		vertical-align: middle;
		padding-left: 1.875rem;
		transition: right 0.2s ease-out;
	}

	&:hover,
	&:focus,
	&:active {
		color: $base-text-color;

		&:after {
			right: -0.25rem;
		}
	}
	/*&:focus {
		@extend button:hover;
	}*/
	&:active {
		font-weight: 600;
		background-color: darken($color-secondary, 10); // Darken instead of lighten
	}

	&.-action {
		background: mix($color-primary, #000, 80%);
		top: 7.5rem;
		right: 0;
		z-index: 100;
		width: 100%;
		border-radius: 0;
		color: $color-secondary;

		&:after {
			color: #fff;
			animation: left-right 1s infinite;
		}

		&:hover {
			background: mix($color-primary, #000, 60%);
		}

		@include bp-min(m) {
			position: absolute !important;
			width: auto;
			background: $color-secondary;
			color: #000;

			&:hover {
				background-color: darken($color-secondary, 5); // Darken instead of lighten
			}

			&:after {
				color: $color-primary;
			}
		}
	}

	@keyframes left-right {
		0% {
			right: 0;
		}

		50% {
			right: -0.25rem;
		}

		100% {
			right: 0;
		}
	}

	@-webkit-keyframes left-right {
		0% {
			right: 0;
		}

		50% {
			right: -0.25rem;
		}

		100% {
			right: 0;
		}
	}


	&.-green {
		background-color: $color-primary;
		color: #fff;

		&:active {
			background-color: darken($color-primary, 10); // Darken instead of lighten
		}
	}

	&.-white {
		background-color: #fff;

		&:active {
			background-color: $color-secondary-light;
		}
	}

	&.-big {
		font-size: 1.25rem;
		padding-top: 1rem;
		padding-bottom: 1rem;

		&.-icon {
			padding-left: 6.25rem; // 4.375rem + 1.875rem
			position: relative;
		}

		.icon {
			background: $color-secondary-light;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 4.375rem;
			line-height: 4rem;
			border-radius: 0.25rem 0 0 0.25rem;
			font-size: 175%;
			color: $color-secondary;
		}
	}

	&.-block {
		display: block;
		width: 100%;
		padding-right: 3.75rem;
		margin-bottom: 1.25rem;
		text-align: left;
		position: relative;

		&:after {
			position: absolute;
			right: 1.875rem;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover,
		&:focus,
		&:active {

			&:after {
				right: 1.5rem;
			}
		}

		.icon {
			text-align: center;
		}
	}

	&.icon-only {
		font-size: 100%;
		line-height: 3.125rem;
		width: 3.125rem;
		height: 3.125rem;
		padding: 0;

		&:after {
			content: none;
		}

		&.border {
			border: 0.375rem solid #fff;
			width: 3.5rem;
			height: 3.5rem;
			line-height: 2.75rem;
		}

		&.round {
			border-radius: 1.563rem;

			&.border {
				border-radius: 1.75rem;
			}

			.icon {
				font-size: 125%;
				line-height: 1;

				&:before {
					vertical-align: middle;
				}
			}
		}
	}
}

a.button,
a span.button {
	@extend button;

	&.disabled {
		@extend [disabled];
	}
}

.buttons {
	float: left;
	margin: 0 -0.5rem;

	.button {
		margin: 0 0.5rem;
	}
}
