﻿.logo {
	@extend .pull;
	background: url(../../images/logo-financieel-servicepunt.svg) no-repeat center;
	background-size: 100% auto;
	width: 15rem; // 240px
	height: 7.5rem; // 120px
	margin: 0 -1.25rem;

	&:focus {
		background-color: rgba(#000, 0.1);
	}
}