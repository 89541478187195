﻿@import "~swiper/dist/css/swiper.css";

.swiper-container-horizontal {
	position: relative;
	overflow: hidden;
	padding: 0 2rem;
	margin: 0 -2rem;

	/*&.slider-topics {
		padding: 0;
		margin: 0;
	}*/
}

// Navigation
.swiper-button-prev,
.swiper-button-next {
	font-size: 100%;
	transform: translateY(-50%);

	.slider-experiences & {
		transform: translateY(-70%);
	}

	&.swiper-button-disabled {
		opacity: 0;
		transition: opacity 0.2s ease-out;
	}
}

.swiper-button-prev {
	left: 0;

	@media (min-width: $grid-breakpoint-m-min) {
		left: -0.375rem;
	}
}

.swiper-button-next {
	right: 0;

	@media (min-width: $grid-breakpoint-m-min) {
		right: -0.375rem;
	}
}
