﻿ul.contact {
	@extend .no-bullets;
	overflow: hidden;

	li {
		width: 100%;
	}

	a {
		text-decoration: underline;

		&:active {
			font-weight: bold;
		}

		&[href^=tel] {
			text-decoration: none;
			color: $base-text-color;

			&:focus,
			&:active {
				text-decoration: underline;
			}
		}
	}
}