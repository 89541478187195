﻿.accordion {
	border: none;
	margin: 2rem 0;
	overflow: hidden;

	.accordion-head {
		position: relative;
		padding: 0;
		margin: 0;
		cursor: pointer;

		> a {
			text-decoration: none;
			padding: 0;

			&[aria-expanded="true"] .icon-arrow-down:before {
				content: $icon-arrow-up;
			}
		}

		h3 {
			padding: 0;

			.icon-only.button {
				margin-top: 0;
				margin-right: 1rem;
				margin-bottom: .5rem;

				.icon-arrow-down:before {
					display: inline-block;
					color: $color-primary;
					transition: all 250ms ease-in-out 0s;
				}
			}
		}

		a:hover,
		a:focus,
		a:active {
			h3 {
				text-decoration: underline;
			}
		}

		h3:active,
		> a:active + h3 {
			color: $base-text-color;
			border: none;
		}
	}

	.accordion-body {
		padding: 0;
		border: none;

		.text {
			background: $color-primary-light;
			padding: 1rem 2rem;
			border-radius: 0.25rem;
			overflow: hidden;
			margin-top: 1rem;

			.content-block:nth-of-type(even) & {
				background: #fff;
			}
		}
	}
}
