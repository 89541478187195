﻿.container {
	box-sizing: border-box;

	&.-relative {
		position: relative;
	}

	&.-vertical-align {
		@media (min-width: $grid-breakpoint-m-min) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			&.-vertical-align-elements {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}
}
