﻿.call-to-action {
	padding: 2rem 0;
	background: $color-primary;
	position: relative;

	@media (min-width: $grid-breakpoint-m-min) {
		min-height: 9.875rem;
	}

	hgroup {
		max-width: 55rem;

		+ p {
			@media (min-width: $grid-breakpoint-m-min) {
				margin-left: auto;
			}
		}
	}
}
