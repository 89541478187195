﻿.link-list {
	@extend .no-bullets;
	overflow: hidden;
	border-top: 1px solid $color-secondary;
	margin: 0;

	li {
		float: left;
		width: 100%;
		border-bottom: 1px solid $color-secondary;
	}

	a {
		padding-right: 0.625rem;
		padding-left: 0.625rem;
	}
}
