﻿.singlechoice {
	> label:first-of-type {
		@extend visuallyhidden;
	}


	.radio-button {
		display: inline-block;
		overflow: hidden;

		input {
			@extend visuallyhidden;

			&:checked ~ label {

				&:after {
					background: $color-primary;
				}
			}
		}

		label {
			position: relative;
			display: inline-block;
			width: auto !important;
			padding: 0.5rem 0 0.5rem 1.875rem;
			margin: 0 2rem 0 0;
			cursor: pointer;

			&:before {
				position: absolute;
				content: '';
				width: 1.25rem;
				height: 1.25rem;
				padding: 0.25rem 0 0;
				border: 1px solid $color-secondary;
				border-radius: 100%;
				top: 0.875rem;
				left: 0;
				font-size: 75%;
				text-align: center;
				background: #fff;
			}

			&:after {
				position: absolute;
				content: '';
				width: 0.75rem;
				height: 0.75rem;
				border-radius: 100%;
				top: 1.125rem;
				left: 0.25rem;
				background: none;
				transition: opacity 0.2s ease-in-out;
			}

			&:hover:after {
				background: $color-primary-light;
			}
		}
	}
}
