﻿.image {
	margin: 0 0 1rem;

	&__container {
		@include responsive-container-psudo(664, 442);
		overflow: hidden;
		width: 100%;
		margin: 0 0 1rem;

		@media (min-width: $grid-breakpoint-m-min) {
			float: right;
			max-width: 41.5rem;
			margin: 0.25rem 0 1rem $grid-column-gutter;
		}

		@media (min-width: $grid-breakpoint-xxl-min) {
			max-width: 28.75rem; // 460px;
		}

		&.-full-width {
			@include responsive-container-psudo(1378, 416);
			float: none;
			max-width: initial;
			margin: 0.75rem 0;
		}

		&.-small {
			@media (min-width: $grid-breakpoint-s-min) {
				float: right;
				max-width: 26.563rem; // 425px;
				margin: 0.25rem 0 1rem $grid-column-gutter;

				.-background-image & {
					max-width: 20rem; // 320px
				}
			}
		}

		&.-left {
			float: left;

			@media (min-width: $grid-breakpoint-m-min) {
				margin: 0.25rem $grid-column-gutter 1rem 0;
			}
		}
	}

	&.full-width {
		margin: 0 auto;
		width: 100%;

		.-image {
			width: 100%;
			height: auto;
		}
	}
}

.-image {
	border-radius: 0.25rem;
}

.background-image {
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
	object-position: center;
}