﻿.content-block {
	@extend .clearfix;
	background: #fff;
	position: relative;
	padding: 2rem 0;

	@media (min-width: $grid-breakpoint-m-min) {
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;
	}

	&:nth-of-type(even) {
		background: $color-primary-light;
	}

	&.-background-image {
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;

		@media (min-width: $grid-breakpoint-m-min) {
			padding-top: 6.25rem;
			padding-bottom: 6.25rem;
		}
	}

	&.-background-yellow {
		background-color: $color-secondary;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	&.-background-white {
		background-color: #fff;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	h2 {
		margin-top: 0;
	}

	.button,
	.arrow {
		margin-top: 0.75rem;

		&.-no-float {
			float: none;
		}

		@media (min-width: $grid-breakpoint-s-min) {
			+ .button,
			+ .arrow {
				margin-left: 1.875rem;
			}
		}
	}

	.content {
		background: #fff;
		padding: $grid-column-gutter;
		border-radius: 0.25rem;
		overflow: hidden;
		position: relative;
		z-index: 2;
	}
}
