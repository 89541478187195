﻿.hero {
	background: $color-primary url(../../images/fsp-element-yellow.svg) 100% 100% no-repeat;
	background-size: 25% auto;
	padding: 0.5rem;

	@media (min-width: $grid-breakpoint-m-min) {
		background-size: 320px auto;
		padding: 1.5rem 0;
	}

	&.-padding-top {
		padding-top: ($form-input-height-large + 0.5rem);

		@media (min-width: $grid-breakpoint-m-min) {
			padding: 1.5rem 0;
		}
	}

	&.-frontpage {
		position: relative;
		padding: 0;
		overflow: hidden;
		background-size: 50% auto;

		&.-padding-top {
			padding-top: $form-input-height-large;

			@media (min-width: $grid-breakpoint-m-min) {
				padding-top: 0;
			}
		}

		@media (min-width: $grid-breakpoint-m-min) {
			background-size: 33% auto;
		}

		@media (min-width: $grid-breakpoint-l-min) {
			background-image: none;
		}

		.content {
			padding: 1rem 0 2rem;

			@media (min-width: $grid-breakpoint-m-min) {
				padding-right: 50%;
			}

			@media (min-width: $grid-breakpoint-l-min) {
				background: $color-primary url(../../images/fsp-element-yellow.svg) 100% 100% no-repeat;
				background-size: auto 66%;
				width: 50%;
				min-height: 37.5rem; // 600px
				padding-right: 0;
				padding-top: 5rem;
				padding-bottom: 5rem;

				h1,
				p,
				.btn-group {
					max-width: 26.25rem; // 420px
				}
			}

			p {
				color: #fff;
				text-shadow: 0 0 1px #000;
			}
		}
	}
}

.hero-image {
	display: none;

	figcaption {
		display: none;
	}

	@media (min-width: $grid-breakpoint-l-min) {
		display: block;
		margin: 0;
		position: absolute;
		top: 0;
		right: 0;
		width: 50%;
		height: 100%;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}

		figcaption {
			background: rgba($color-primary, 0.6);
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			padding: 0 3rem;
			display: flex;
			align-items: flex-end;
		}
	}
}