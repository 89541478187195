﻿.breadcrumbs {
	background: $color-secondary;
	position: relative;
	padding: 1rem 0;
	margin: 0;
	color: $base-text-color;
	font-weight: bold;
	font-family: $base-font-title;

	ul.breadcrumbs {
		@extend .no-bullets;
		overflow: hidden;
		padding: 0;

		li {
			@extend .pull;

			& + li:before {
				@include icon($icon-chevron-right);
				display: inline-block;
				font-size: 50%;
				vertical-align: middle;
				margin: 0 0.5rem;
			}
		}

		a {
			color: $base-text-color;
			font-weight: 600;
			text-decoration: none;

			&:hover,
			&:focus,
			&:active {
				text-decoration: underline;
			}

			&:active {
				font-weight: bold;
			}
		}
	}
}
