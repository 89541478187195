﻿.back-to-top {
	@media (min-width: $grid-breakpoint-m-min) {
		position: fixed;
		bottom: 2.5rem;
		left: 50%;
		z-index: 1000;
		margin-left: -775px;
		opacity: 0;
		transition: opacity 250ms ease-in-out 0s;

		&.headroom--unpinned {
			opacity: 1;
		}
	}
}
