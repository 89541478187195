﻿.css-columns {
	@media (min-width: $grid-breakpoint-m-min) {
		column-count: 2;
		column-gap: $grid-column-gutter;
		margin: 1.25rem 0;

		&.-triple {
			column-count: 3;
		}

		p {
			margin-top: 0;
		}
	}
}
