﻿.checkbox {
	input {
		@extend visuallyhidden;


		&:checked + label {

			&:before {
				@include icon($icon-check);
				color: $color-primary;
			}
		}
	}

	label {
		position: relative;
		padding: 0 0 0 2rem;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			width: 1.25rem;
			height: 1.25rem;
			padding: 0.125rem 0 0;
			border: 1px solid $color-secondary;
			top: 0.375rem;
			left: 0;
			font-size: 75%;
			text-align: center;
			background: #fff;
			vertical-align: middle;
		}

		p {
			margin: 0;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		&:hover:before {
			@include icon($icon-check);
			color: $color-primary-light;
		}
	}
}
