﻿.slider-topics {
	margin: 1rem 0;

	&:before {
		width: 100%;
		width: calc(100% - 4rem);
		height: 100%;
		border-radius: 0.25rem;
		position: absolute;
		top: 0;
		left: 2rem;
		right: 2rem;
		content: '';
		background: #fff;
	}

	.swiper-button-prev {
		left: 0.25rem;
	}

	.swiper-button-next {
		right: 0.25rem;
	}

	.swiper-wrapper {
		.swiper-slide-prev {
			opacity: 0;
		}
	}

	.topic {
		box-sizing: border-box;

		a {
			display: block;
			text-decoration: none;
			padding: 2.5rem 0;

			&:hover,
			&:focus,
			&:active {
				h3 {
					text-decoration: underline;
				}
			}

			&:active h3 {
				font-weight: bold;
			}
		}
	}
}
